import { render, staticRenderFns } from "./RequestEvent.vue?vue&type=template&id=8c895b00&"
import script from "./RequestEvent.vue?vue&type=script&lang=js&"
export * from "./RequestEvent.vue?vue&type=script&lang=js&"
import style0 from "./RequestEvent.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports