<template>
  <div class="page request-event-page typo">

    <!-- Meldungen -->
     <div
      v-if="$store.state.requestEvent.result > 0"
      class="row">
        <div
          :class="{
            'success': $store.state.requestEvent.result === 1,
            'error': $store.state.requestEvent.result >= 10
          }"
          class="col is-10 is-offset-1 is-12-sm is-no-offset-sm text msg">
            <p v-if="$store.state.requestEvent.result === 1">
              Vielen Dank für Ihre Buchungsanfrage. Wir werden uns so schnell wie möglich mit Ihnen in 
              Verbindung setzen und Ihnen ein Angebot schicken.
            </p>
            <p v-if="$store.state.requestEvent.result === 10">
              Es tut uns leid, ein unbekannter Fehler ist aufgetreten. Ihre Anfrage wurde nicht 
              übermittelt. Bitte versuchen Sie es erneut oder setzen sich direkt mit uns in Verbindung.
            </p>
            <p v-if="$store.state.requestEvent.result === 11">
              Ihre Angaben sind unvollständig. Bitte prüfen Sie die markierten Felder und schicken 
              das Formular erneut ab. Vielen Dank.
            </p>
        </div>
    </div>

    <sections-elem :content="$store.state.page.content.sections"></sections-elem>

    <!-- Angaben zum Veranstalter -->
    <div class="row">
      <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm formgroup text">
        <hr />
        <h3 class="legend">Angaben zum Veranstalter</h3>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestEvent.data.company.required">
            Veranstalter
        </label-control>
      </div>
      <div class="col is-5 is-8-sm control">
        <text-control
          v-model="$store.state.requestEvent.data.company.value"
          :error="$store.state.requestEvent.data.company.error">
        </text-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestEvent.data.salutation.required">
            Anrede
        </label-control>
      </div>
      <div class="col is-2 is-8-sm control">
        <select-control
          v-model="$store.state.requestEvent.data.salutation.value"
          :options="$store.state.requestEvent.data.salutation.options"
          :error="$store.state.requestEvent.data.salutation.error">
        </select-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestEvent.data.prename.required">
            Vorname
        </label-control>
      </div>
      <div class="col is-5 is-8-sm control">
        <text-control
          v-model="$store.state.requestEvent.data.prename.value"
          :error="$store.state.requestEvent.data.prename.error">
        </text-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestEvent.data.surname.required">
            Name
        </label-control>
      </div>
      <div class="col is-5 is-8-sm control">
        <text-control
          v-model="$store.state.requestEvent.data.surname.value"
          :error="$store.state.requestEvent.data.surname.error">
        </text-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestEvent.data.street.required">
            Straße
        </label-control>
      </div>
      <div class="col is-5 is-8-sm control">
        <text-control
          v-model="$store.state.requestEvent.data.street.value"
          :error="$store.state.requestEvent.data.street.error">
        </text-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestEvent.data.zip.required">
            PLZ
        </label-control>
        <label-control
          :required="$store.state.requestEvent.data.town.required"
           class="is-hidden-sm">
            Ort
        </label-control>
      </div>
      <div class="col is-1 is-8-sm control">
        <text-control
          v-model="$store.state.requestEvent.data.zip.value"
          :error="$store.state.requestEvent.data.zip.error">
        </text-control>
      </div>
      <div class="col is-4 is-hidden-sm control">
        <text-control
          v-model="$store.state.requestEvent.data.town.value"
          :error="$store.state.requestEvent.data.town.error">
        </text-control>
      </div>
    </div>
    <div class="row is-hidden-lg-only is-hidden-md-only">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestEvent.data.town.required">
            Ort
        </label-control>
      </div>
      <div class="col is-8-sm control">
        <text-control
          v-model="$store.state.requestEvent.data.town.value"
          :error="$store.state.requestEvent.data.town.error">
        </text-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestEvent.data.phone.required">
            Telefon
        </label-control>
      </div>
      <div class="col is-5 is-8-sm control">
        <text-control
          v-model="$store.state.requestEvent.data.phone.value"
          :error="$store.state.requestEvent.data.phone.error">
        </text-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestEvent.data.email.required">
            E-Mail
        </label-control>
      </div>
      <div class="col is-5 is-8-sm control">
        <text-control
          v-model="$store.state.requestEvent.data.email.value"
          :isEmail="true"
          :error="$store.state.requestEvent.data.email.error">
        </text-control>
      </div>
    </div>

    <!-- Art der Veranstaltung -->
    <div class="row">
      <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm formgroup text">
        <hr />
        <h3 class="legend">Informationen zur geplanten Veranstaltung</h3>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestEvent.data.eventTitle.required">
            Titel, Thema
        </label-control>
      </div>
      <div class="col is-5 is-8-sm control">
        <text-control
          v-model="$store.state.requestEvent.data.eventTitle.value"
          :error="$store.state.requestEvent.data.eventTitle.error">
        </text-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestEvent.data.eventDescription.required">
            Beschreibung
        </label-control>
      </div>
      <div class="col is-5 is-8-sm control">
        <textarea-control
          v-model="$store.state.requestEvent.data.eventDescription.value"
          :error="$store.state.requestEvent.data.eventDescription.error"
          class="is-small">
        </textarea-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestEvent.data.eventType.required">
            Art
        </label-control>
      </div>
       <div class="col is-5 is-8-sm control">
        <select-control
          v-model="$store.state.requestEvent.data.eventType.value"
          :options="$store.state.requestEvent.data.eventType.options"
          :error="$store.state.requestEvent.data.eventType.error">
        </select-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-5 is-offset-4 is-8-sm is-offset-4-sm is-12-xs is-no-offset-xs control">
        <checkbox-control
          v-model="$store.state.requestEvent.data.eventPublic.value"
          :error="$store.state.requestEvent.data.eventPublic.error">
            öffentlich
        </checkbox-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestEvent.data.eventDatetime.required">
            gewünschter Termin, Uhrzeit
        </label-control>
      </div>
      <div class="col is-5 is-8-sm control">
        <textarea-control
          v-model="$store.state.requestEvent.data.eventDatetime.value"
          :error="$store.state.requestEvent.data.eventDatetime.error"
          class="is-small">
        </textarea-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestEvent.data.participantsCount.required">
            Geplante Anzahl Teilnehmer<span class="is-hidden-lg-only is-hidden-md-only is-hidden-sm-only">&nbsp;({{ $store.state.requestEvent.data.participantsCount.max }} max.)</span>
        </label-control>
      </div>
      <div class="col is-1 is-2-sm control">
        <number-control
          v-model="$store.state.requestEvent.data.participantsCount.value"
          :min="$store.state.requestEvent.data.participantsCount.min"
          :max="$store.state.requestEvent.data.participantsCount.max"
          :error="$store.state.requestEvent.data.participantsCount.error">
        </number-control>
      </div>
      <div class="col is-6 is-hidden-xs control">
        ({{ $store.state.requestEvent.data.participantsCount.max }} max.)
      </div>
    </div>
    <div
      v-if="$store.state.requestEvent.data.eventType.value === 'workshop'"
      class="row">
        <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
          <label-control
            :required="$store.state.requestEvent.data.overnightCount.required">
              Anzahl Übernachtungsgäste<span class="is-hidden-lg-only is-hidden-md-only is-hidden-sm-only">&nbsp;maximal</span>
          </label-control>
        </div>
        <div class="col is-1 is-2-sm control">
          <number-control
            v-model="$store.state.requestEvent.data.overnightCount.value"
            :error="$store.state.requestEvent.data.overnightCount.error">
          </number-control>
        </div>
        <div class="col is-6 is-hidden-xs control">
          maximal
        </div>
    </div>

    <!-- Räume und Ausstattung -->
    <div class="row">
      <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm formgroup text">
        <hr />
        <h3 class="legend">Erforderliche Räume und Ausstattung</h3>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control>Benötigte Räume</label-control>
      </div>
      <div class="col is-5 is-8-sm control">
        <checkbox-control
          v-model="$store.state.requestEvent.data.room1.value"
          :error="$store.state.requestEvent.data.room1.error">
            Multifunktionaler Veranstaltungsraum (40-60 Personen)
        </checkbox-control>
        <checkbox-control
          v-model="$store.state.requestEvent.data.room2.value"
          :error="$store.state.requestEvent.data.room2.error">
            Atelier und Werkraum (20 Personen)
        </checkbox-control>
        <checkbox-control
          v-model="$store.state.requestEvent.data.room3.value"
          :error="$store.state.requestEvent.data.room3.error">
            Meditations- und Gesprächsraum (ca. 50qm)
        </checkbox-control>
        <checkbox-control
          v-model="$store.state.requestEvent.data.roomUnknown.value"
          :error="$store.state.requestEvent.data.roomUnknown.error">
            unbekannt, Beratung erforderlich
        </checkbox-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control>Ausstattung</label-control>
      </div>
      <div class="col is-5 is-8-sm control">
        <div class="row is-multiline">
          <div class="col is-6 is-12-sm second-level">
            <checkbox-control
              v-model="$store.state.requestEvent.data.equipmentBeamer.value"
              :error="$store.state.requestEvent.data.equipmentBeamer.error">
                Beamer
            </checkbox-control>
            <checkbox-control
              v-model="$store.state.requestEvent.data.equipmentScreen.value"
              :error="$store.state.requestEvent.data.equipmentScreen.error">
                Leinwand
            </checkbox-control>
            <checkbox-control
              v-model="$store.state.requestEvent.data.equipmentFlipchart.value"
              :error="$store.state.requestEvent.data.equipmentFlipchart.error">
                Flipchart
            </checkbox-control>
          </div>
          <div class="col is-6 is-12-sm second-level">
            <checkbox-control
              v-model="$store.state.requestEvent.data.equipmentPiano.value"
              :error="$store.state.requestEvent.data.equipmentPiano.error">
                Klavier
            </checkbox-control>
            <checkbox-control
              v-model="$store.state.requestEvent.data.equipmentWlan.value"
              :error="$store.state.requestEvent.data.equipmentWlan.error">
                WLAN
            </checkbox-control>
          </div>
        </div>
      </div>
      <div class="col is-3 is-8-sm control">
        
      </div>
    </div>

    <!-- Verpflegung -->
    <div class="row">
      <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm formgroup text">
        <hr />
        <h3 class="legend">Vegetarische Verpflegung</h3>
      </div>
    </div>
    <div
      v-if="hasCateringText"
      class="row">
        <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm text">
          <text-elem :text="$store.state.page.content.cateringtext"></text-elem>
        </div>
    </div>
    <div class="row">
      <div class="col is-5 is-offset-4 is-8-sm is-offset-4-sm is-12-xs is-no-offset-xs control">
        <radio-control
          v-model="$store.state.requestEvent.data.catering.value"
          :options="$store.state.requestEvent.data.catering.options"
          :error="$store.state.requestEvent.data.catering.error">
        </radio-control>
      </div>
    </div>

    <!-- Abschlusstext -->
    <div class="row">
      <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm formgroup text">
        <hr />
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestEvent.data.message.required">
            Ihre Nachricht
        </label-control>
      </div>
      <div class="col is-5 is-8-sm control">
        <textarea-control
          v-model="$store.state.requestEvent.data.message.value"
          :error="$store.state.requestEvent.data.message.error">
        </textarea-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm formgroup text">
        <hr />
      </div>
    </div>
    <div class="row">
      <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm text">
        <text-elem :text="$store.state.page.content.submittext"></text-elem>
      </div>
    </div>
    <div class="row">
      <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm control">
        <checkbox-control
          v-model="$store.state.requestEvent.data.conditions.value"
          :error="$store.state.requestEvent.data.conditions.error">
            <text-elem :text="$store.state.page.content.conditionstext"></text-elem>
        </checkbox-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm action">
        <button-elem
          @click="onSubmit($event)"
          :spinner="submitProgress"
          class="is-medium is-outline">
            {{ $store.state.page.content.button.value }}
        </button-elem>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Scroll from '@/services/Scroll'
import TextControl from '@/components/controls/Text'
import NumberControl from '@/components/controls/Number'
import RadioControl from '@/components/controls/Radio'
import TextareaControl from '@/components/controls/Textarea'
import SelectControl from '@/components/controls/Select'
import CheckboxControl from '@/components/controls/Checkbox'
import LabelControl from '@/components/controls/Label'

export default {
  name: 'request-event-page',
  components: {
    TextControl,
    NumberControl,
    RadioControl,
    TextareaControl,
    SelectControl,
    CheckboxControl,
    LabelControl
  },
  data () {
    return {
      submitProgress: false
    }
  },
  created () {
    this.$store.commit('requestEvent/initForm')
  },
  computed: {
    hasCateringText () {
      return this.$store.state.page.content.cateringtext.value.length > 0
    }
  },
  methods: {
    onSubmit (Event) {
      this.submitProgress = true
      this.$store.dispatch('requestEvent/submit')
      .then(() => {
        this.$nextTick(() => {
          Scroll.smooth(0)
          this.submitProgress = false
        })
      })
    }
  }
}
</script>

<style lang="sass">
.request-event-page
  .col
    &.second-level
      padding-top: 0
      padding-bottom: 0
  .msg
    margin-bottom: m(2)
    padding: m(2) m(3)
    p
      color: $white
    &.error
      background-color: $warning-color
    &.success
      background-color: $success-color
  .label-control
    &+.label-control
      &:before
        margin-left: m(1)
        margin-right: m(1)
        content: "/"
  .action
    padding-top: m(4)
    text-align: center
  p
    &.has-error
      color: $red
  .calculation
    .total,
    .info
      text-align: right
    &.has-border
      margin-top: m(1)
      .col
        padding-top: m(2)
        border-top: 1px solid $border-color

+xs
  .request-event-page
    .col
      &.label
        padding-bottom: 0
      &.control
        padding-top: 0
    .calculation
      .info
        text-align: left
</style>